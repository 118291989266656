import React, { useEffect, useState } from "react";
import CreateCollection from "../../Components/CreateCollection/CreateCollection";
import SignInContent from "../../Components/SignInContent/SignInContent";

const SignIn = () => {
  return (
    <>
      <SignInContent />
    </>
  );
};

export default SignIn;
