import armyLogo from "../assets/images/soldier-asset-1.svg";
// Adjust the path to your actual image location


export const dummyTags = [
  {name:"Army", id:1},
  {name:"Trending", id:2},
  {name:"Live", id:3},
  {name:"Sports", id:4},
  {name:"Shoes", id:5},
  {name:"Clothes", id:6},
  {name:"Health", id:7},
  {name:"Education", id:8},
]
export const pointMockData = [
  {
    image: armyLogo,
    level: 1,
    name: "Soldier 1",
    pointsRequired: 100,
    recruitMultiplier: "1x",
    finger: "Silver",
    votingPower: "1x",
    cashPoolEntries: "1x",
    nftRewardsEntries: "1x",
    platinumExperienceEntries: "1x",
    irlActivations: "No Access",
    vipAfterparties: "No Access",
    unfkMerch: "None",
  },
  {
    image: armyLogo,
    level: 2,
    name: "Soldier 2",
    pointsRequired: 200,
    recruitMultiplier: "2x",
    finger: "Gold",
    votingPower: "2x",
    cashPoolEntries: "2x",
    nftRewardsEntries: "2x",
    platinumExperienceEntries: "2x",
    irlActivations: "Access Granted",
    vipAfterparties: "No Access",
    unfkMerch: "Limited",
  },
  {
    image: armyLogo,
    level: 3,
    name: "Soldier 3",
    pointsRequired: 300,
    recruitMultiplier: "3x",
    finger: "Gold",
    votingPower: "3x",
    cashPoolEntries: "3x",
    nftRewardsEntries: "3x",
    platinumExperienceEntries: "3x",
    irlActivations: "Access Granted",
    vipAfterparties: "No Access",
    unfkMerch: "Standard",
  },
  {
    image: armyLogo,
    level: 4,
    name: "Soldier 4",
    pointsRequired: 400,
    recruitMultiplier: "4x",
    finger: "Platinum",
    votingPower: "4x",
    cashPoolEntries: "4x",
    nftRewardsEntries: "4x",
    platinumExperienceEntries: "4x",
    irlActivations: "Access Granted",
    vipAfterparties: "Access Granted",
    unfkMerch: "Standard",
  },
  {
    image: armyLogo,
    level: 5,
    name: "Soldier 5",
    pointsRequired: 500,
    recruitMultiplier: "5x",
    finger: "Platinum",
    votingPower: "5x",
    cashPoolEntries: "5x",
    nftRewardsEntries: "5x",
    platinumExperienceEntries: "5x",
    irlActivations: "Full Access",
    vipAfterparties: "Access Granted",
    unfkMerch: "Premium",
  },
  {
    image: armyLogo,
    level: 6,
    name: "Soldier 6",
    pointsRequired: 600,
    recruitMultiplier: "6x",
    finger: "Diamond",
    votingPower: "6x",
    cashPoolEntries: "6x",
    nftRewardsEntries: "6x",
    platinumExperienceEntries: "6x",
    irlActivations: "Full Access",
    vipAfterparties: "Access Granted",
    unfkMerch: "Premium",
  },
  {
    image: armyLogo,
    level: 7,
    name: "Soldier 7",
    pointsRequired: 700,
    recruitMultiplier: "7x",
    finger: "Diamond",
    votingPower: "7x",
    cashPoolEntries: "7x",
    nftRewardsEntries: "7x",
    platinumExperienceEntries: "7x",
    irlActivations: "Full Access",
    vipAfterparties: "VIP Access",
    unfkMerch: "Premium",
  },
  {
    image: armyLogo,
    level: 8,
    name: "Soldier 8",
    pointsRequired: 800,
    recruitMultiplier: "8x",
    finger: "Elite",
    votingPower: "8x",
    cashPoolEntries: "8x",
    nftRewardsEntries: "8x",
    platinumExperienceEntries: "8x",
    irlActivations: "Full Access",
    vipAfterparties: "VIP Access",
    unfkMerch: "Elite",
  },
  {
    image: armyLogo,
    level: 9,
    name: "Soldier 9",
    pointsRequired: 900,
    recruitMultiplier: "9x",
    finger: "Elite",
    votingPower: "9x",
    cashPoolEntries: "9x",
    nftRewardsEntries: "9x",
    platinumExperienceEntries: "9x",
    irlActivations: "Full Access",
    vipAfterparties: "VIP Access",
    unfkMerch: "Elite",
  },
  {
    image: armyLogo,
    level: 10,
    name: "Soldier 10",
    pointsRequired: 1000,
    recruitMultiplier: "10x",
    finger: "Legendary",
    votingPower: "10x",
    cashPoolEntries: "10x",
    nftRewardsEntries: "10x",
    platinumExperienceEntries: "10x",
    irlActivations: "Full Access",
    vipAfterparties: "VIP Access",
    unfkMerch: "Legendary",
  },
];


export const campaignData = [
  {
    name: "Stix Campaign",
    status: true,
    participant: 20,
    tweetsNo:21,
  },
  {
    name: "December Campaign",
    status: false,
    participant: 21,
    tweetsNo:26,
  },
]

export const generalLeaderBoardData = [
  {
    rank: 1,
    name: "Jameslee201",
    image: "https://picsum.photos/id/1/200",
    actPts: "8,242",
    multiplier: "4x",
  },
  {
    rank: 2,
    name: "Sameslee201",
    image: "https://picsum.photos/id/2/200",
    actPts: "8,242",
    multiplier: "1x",
  },
  {
    rank: 3,
    name: "Shaameslee201",
    image: "https://picsum.photos/id/3/200",
    actPts: "8,200",
    multiplier: "10x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
];
export const campaignLeaderBoardData = [
  {
    rank: 1,
    name: "Daniel3402",
    image: "https://picsum.photos/id/10/200",
    actPts: "8,242",
    multiplier: "20x",
  },
  {
    rank: 2,
    name: "boomeslee201",
    image: "https://picsum.photos/id/22/200",
    actPts: "9,242",
    multiplier: "16x",
  },
  {
    rank: 3,
    name: "Jacoee201",
    image: "https://picsum.photos/id/32/200",
    actPts: "81,200",
    multiplier: "11x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "2x",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
  {
    rank: 4,
    name: "Liksaslee01",
    image: "https://picsum.photos/id/4/200",
    actPts: "8,700",
    multiplier: "11,642",
  },
];
