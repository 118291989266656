//@ts-nocheck

import generalIcon from "../assets/images/general-rank.PNG";
import captainIcon from "../assets/images/captain-rank.PNG";
import masterChiefIcon from "../assets/images/master-chief-rank.PNG";
import sergeantIcon from "../assets/images/sergeant-rank.PNG";
import majorIcon from "../assets/images/major-rank.PNG";
import corporalIcon from "../assets/images/corporal-rank.PNG";
import privateIcon from "../assets/images/private-rank.PNG";

const rankIcons = {
  General: generalIcon,
  Captain: captainIcon,
  Sergeant: sergeantIcon,
  Corporal: corporalIcon,
  Major: majorIcon,
  Master: masterChiefIcon,
  Private: privateIcon,
};

export default rankIcons;
